import React from "react";
import img1 from "../../Assets/team/ceo.png";

const Ceo = () => {
  return (
    <section className="">
      <div className=" grid grid-cols-1 lg:grid-cols-1 gap-5">
        <div className="w-full">
          <h2 className="font-bold p-3 text-center text-2xl uppercase bg-secondary text-white">
            Message from CEO
          </h2>
          <div className=" lg:flex  gap-5  shadow-md  p-5">
            <img class="h-auto lg:max-w-xs rounded-lg" src={img1} alt=""></img>
            <div>
              {" "}
              <h1>Mahadi Hasan</h1>
              <p className=" text-xs">  Chairman & CEO</p>
            </div>
          </div>
          <p className="text-textColor text-[14px] pt-6">
            Dear valued customers and stakeholders,
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            I hope this message finds you in good health and high spirits. It is
            with great pleasure that I reach out to you as the CEO of Renix
            Unani Laboratories Ltd. I would like to express my sincere gratitude
            for your unwavering support and trust in our company.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            At Renix Unani Laboratories Ltd., we remain dedicated to our mission
            of improving healthcare through the production of high-quality
            medicines made from natural herbs. Our commitment to promoting the
            benefits of the Unani treatment system, which has been proven
            effective and free from side effects, is unwavering.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            As the CEO, I take immense pride in the positive impact we are
            making in the healthcare industry. Our team of passionate
            professionals is continuously engaged in research and development to
            enhance the quality and efficacy of our products. We invest in
            modern scientific methods to validate the benefits of natural herbs
            and ensure the safety of our medicines.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            We are determined to become a leading pharmaceutical company not
            only in Bangladesh but also beyond, catering to the evolving needs
            of our customers. Our vision is to provide holistic healthcare
            solutions that empower individuals to lead healthier and happier
            lives. Through our unwavering commitment to excellence, we aim to
            establish Renix Unani Laboratories Ltd. as a trusted brand in the
            industry.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            Corporate Social Responsibility is a core value at our company, and
            we actively engage in initiatives that contribute to the welfare of
            society. We firmly believe in giving back to the communities we
            serve. From providing free healthcare services to underserved
            communities to supporting education and various social and
            environmental causes, we are committed to making a positive
            difference in society.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            I want to express my heartfelt gratitude to each and every one of
            you for your continuous support. It is your trust and confidence in
            our products and services that drive us to excel. We value your
            feedback and suggestions, as they help us in our journey towards
            continuous improvement.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            As the CEO, I assure you that we will remain dedicated to our
            mission and vision, and we will always strive to exceed your
            expectations. Together, we can make a significant impact on the
            healthcare landscape and contribute to the well-being of individuals
            and communities.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            Thank you once again for your unwavering support. We look forward to
            continuing this journey together and achieving new milestones.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">Sincerely,</p>
          <p className="text-textColor text-[14px]"> CEO</p>
          <p className="text-textColor text-[14px]">
            Renix Unani Laboratories Ltd.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Ceo;
