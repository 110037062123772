import React from "react";
import img1 from "../../Assets/team/founder.png";

const Message = () => {
  return (
    <section className="">
      <div className=" grid grid-cols-1 lg:grid-cols-1 gap-5">
        <div className="w-full">
          <h2 className="font-bold p-3 text-center text-2xl uppercase bg-secondary text-white">
            Message from Founder and Managing Director
          </h2>
          <div className=" lg:flex  gap-5  shadow-md  p-5">
            <img class="h-auto lg:max-w-xs rounded-lg" src={img1} alt=""></img>
            <div>
              {" "}
              <h1>Abu Hanif</h1>
          <p className=" text-xs"> Founder And Managing Director</p>
            </div>
          </div>
          <p className="text-textColor text-[14px] pt-6">
            Dear valued customers and stakeholders,
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            It is my pleasure to welcome you to Renix Unani Laboratories
            Ltd.where we strive to provide high-quality and holistic healthcare
            solutions using all-natural herbs. At Renix Unani Laboratories Ltd.,
            we are committed to promoting the Unani treatment system that has
            been proven to be successful and effective without any side effects.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            As the Managing Director of the company, I take pride in the fact
            that we are contributing to the healthcare industry in a positive
            way by manufacturing high-quality medicine using all-natural herbs.
            Our company is dedicated to promoting the use of alternative
            medicine, and we are continually researching ways to improve our
            products and services to meet the evolving needs of our customers.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            We are committed to our vision of becoming the leading provider of
            Unani treatment solutions in the region, and we believe that our
            success is not just limited to financial gains but also how we
            positively impact the lives of people around us.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            At Renix Unani Laboratories Ltd., we understand the importance of
            Corporate Social Responsibility, and we have taken various
            initiatives to contribute to the welfare of society. Our efforts
            include providing free healthcare services to underserved
            communities, promoting education, and supporting various social and
            environmental causes.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            I would like to thank our customers and stakeholders for their
            continued support, which has enabled us to achieve our goals and
            contribute to the betterment of society. We look forward to
            continuing our efforts to provide the highest quality healthcare
            solutions and make a positive impact on society.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">Sincerely,</p>
          <p className="text-textColor text-[14px]">
            {" "}
            Founder and Managing Director
          </p>
          <p className="text-textColor text-[14px]">
            Renix Unani Laboratories Ltd.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Message;
